import { gql } from "graphql-tag";

export const customerReview = gql`
  fragment Homepage_customer_review on HomepageBodyCustomer_review {
    type
    primary {
      subtitle
    }
  }
`;
