import { gql } from "graphql-tag";

import { documentLink, collectionLink } from "~/app/prismic/prismic-fragments";

export const getTopCategory = (pageType, isNewSliceType = false) => {
  const typePrefix = `${pageType}${isNewSliceType ? "Slices" : "Body"}`;

  return gql`
    fragment ${pageType}_top_category on ${typePrefix}Top_category {
      type
      label
      primary {
        title
      }
      fields {
        link {
          ... documentLink
          ... collectionLink
        }
      }
    }
  ${documentLink}
  ${collectionLink}`;
};
