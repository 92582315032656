import { gql } from "graphql-tag";

import { documentLink, externalLink } from "~/app/prismic/prismic-fragments";

export const getCallToAction = (pageType, isNewSliceType = false) => {
  const typePrefix = `${pageType}${isNewSliceType ? "Slices" : "Body"}`;

  return gql`
    fragment ${pageType}_call_to_action on ${typePrefix}Call_to_action {
      type
      label
      primary {
        title
        paragraph
        anchor_label
        background_color_palette
        text_color_palette
        anchor_link {
          ... externalLink
          ... documentLink
        }
        icon
        bg_image
      }
    }
    ${documentLink}
    ${externalLink}`;
};
