import { render, staticRenderFns } from "./AppBackToTopButton.vue?vue&type=template&id=d099ae1e&"
var script = {}
import style0 from "./AppBackToTopButton.vue?vue&type=style&index=0&id=d099ae1e&prod&lang=postcss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SvgIconDownArrow: require('/home/runner/work/snooze-vue-storefront/snooze-vue-storefront/components/svg/IconDownArrow.vue').default,AppBackToTop: require('/home/runner/work/snooze-vue-storefront/snooze-vue-storefront/components/common/AppBackToTop.vue').default})
