import { gql } from "graphql-tag";

import { documentLink, collectionLink } from "~/app/prismic/prismic-fragments";

export const shopByStyle = gql`
  fragment Homepage_shop_by_style on HomepageBodyShop_by_style {
    type
    label
    primary {
      subTitle
    }
    fields {
      linkLabel
      link {
        ...documentLink
        ...collectionLink
      }
    }
  }
  ${documentLink}
  ${collectionLink}
`;
