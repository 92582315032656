import { gql } from "graphql-tag";

export const heroCarousel = gql`
  fragment Homepage_hero_carousel on HomepageBodyHero_carousel {
    type
    primary {
      time_interval
    }
    fields {
      campaign_sale_link1 {
        ... on Campaign_sale1 {
          _meta {
            uid
          }
        }
      }
    }
  }
`;
