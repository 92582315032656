import { gql } from "graphql-tag";
import { externalLink, documentLink } from "~/app/prismic/prismic-fragments";

export const getImageSectionV2 = pageType => gql`
  fragment ${pageType}_image_section_v2 on ${pageType}BodyImage_section_v2 {
    type
    label
    variation {
        ... on ${pageType}BodyImage_section_v2Default {
            primary {
                image
                image_link {
                ...externalLink
                ...documentLink
              }
            }
        }
    }
  }
  ${externalLink}
  ${documentLink}
`;
